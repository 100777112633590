import React from "react";
import "./GameBoard.css";

import Category from "./Category.js";
import Word from "./Word";
import Alphabets from "./Alphabets";
import GameEnd from "./GameEnd";

import * as Constants from "./Constants";

class GameBoard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alphabetList: Constants.AlphabetsList,
            categoryList: Constants.CategoryList,
            selectedCategory: "",
            categoryWords: "",
            randomWord: "",
            clickedAlphabets: [],
            matchedAlphabets: [],
            wordAlphabets: [],
            gameState: "initial",
            score: 0,
            totalWordCount: Constants.TotalWords,
            categoryWordCount: 0,
            totalLives: Constants.TotalLives,
            isCategoryClicked: "",
            categoryComplete: [],
        };

        this.completedCategory = [];
        this.randomNumber = 0;
        this.finalScore = 0;
        this.clue = "";

        this.selectCategory = this.selectCategory.bind(this);
        this.randomWord = this.randomWord.bind(this);
        this.createWordPlaceholder = this.createWordPlaceholder.bind(this);
        this.checkAlphabet = this.checkAlphabet.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.startGame = this.startGame.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (this.state.randomWord !== "") {
            this.checkAlphabet(e.key.toUpperCase() + "~" + e.keyCode);
        } else {
        }
    }

    startGame() {
        this.completedCategory = [];
        this.setState({
            alphabetList: Constants.AlphabetsList,
            categoryList: Constants.CategoryList,
            selectedCategory: "",
            categoryWords: "",
            randomWord: "",
            clickedAlphabets: [],
            matchedAlphabets: [],
            wordAlphabets: [],
            gameState: "initial",
            score: 0,
            totalWordCount: Constants.TotalWords,
            categoryWordCount: 0,
            totalLives: Constants.TotalLives,
            isCategoryClicked: "",
            categoryComplete: [],
        });
    }

    selectCategory(category) {
        let words = [];
        switch (category.split(" ")[0]) {
            case "Christmas":
                words = Constants.Christmas;
                break;
            case "Coding":
                words = Constants.Coding;
                break;
            case "Colors":
                words = Constants.Colors;
                break;
            case "Countries":
                words = Constants.Countries;
                break;

            default:
                words = [];
        }

        this.setState({
            selectedCategory: category.split(" ")[0],
            categoryWords: words,
            gameState: "running",
            categoryWordCount: words.length,
            totalLives: Constants.TotalLives,
            isCategoryClicked: category.split(" ")[0],
            clickedAlphabets: [],
            matchedAlphabets: [],
            wordAlphabets: [],
        });

        setTimeout(() => {
            this.randomWord();
        }, 100);
    }

    randomWord() {
        let num = Math.floor(Math.random() * this.state.categoryWords.length);
        let word = this.state.categoryWords[num];

        let randomWord = word.split("~")[0];
        this.clue = word.split("~")[1];
        this.randomNumber = num;

        this.setState({
            randomWord: randomWord,
        });

        setTimeout(() => {
            this.createWordPlaceholder();
        }, 100);
    }

    createWordPlaceholder() {
        var word = this.state.randomWord.toUpperCase();
        for (var i = 0; i < word.length; i++) {
            this.state.wordAlphabets.push(word.charAt(i));
        }
        this.setState({
            wordAlphabets: this.state.wordAlphabets,
        });
    }

    checkAlphabet(inputAlphabet) {
        let clickedAlphabets = this.state.clickedAlphabets;
        let keyCode = 0;
        if (this.state.wordAlphabets.includes(" ")) {
            if (clickedAlphabets.indexOf(" ") === -1) {
                clickedAlphabets.push(" ");
                this.state.matchedAlphabets.push(" ");
            }
        }
        if (inputAlphabet.indexOf("~") !== -1) {
            keyCode = parseInt(inputAlphabet.split("~")[1]);
            inputAlphabet = inputAlphabet.split("~")[0];
        }
        if (
            !clickedAlphabets.includes(inputAlphabet) &&
            (keyCode !== 0
                ? (keyCode >= 65 && keyCode <= 90) ||
                  (keyCode >= 97 && keyCode <= 122)
                : true)
        ) {
            clickedAlphabets.push(inputAlphabet);

            this.setState({
                clickedAlphabets: this.state.clickedAlphabets,
            });
            this.state.wordAlphabets.forEach((alphabet) => {
                if (alphabet === inputAlphabet) {
                    this.state.matchedAlphabets.push(inputAlphabet);
                    this.setState({
                        matchedAlphabets: this.state.matchedAlphabets,
                    });
                }
            });
        }

        if (
            !this.state.wordAlphabets.includes(inputAlphabet) &&
            (keyCode !== 0
                ? (keyCode >= 65 && keyCode <= 90) ||
                  (keyCode >= 97 && keyCode <= 122)
                : true)
        ) {
            this.setState((prevState) => {
                return {
                    score: prevState.score,
                    totalLives:
                        prevState.totalLives === 0
                            ? prevState.totalLives
                            : prevState.totalLives - 1,
                };
            });
            if (this.state.totalLives === 0) {
                this.setState({
                    gameState: "end",
                });
            }
        }

        if (
            this.state.wordAlphabets.length ===
            this.state.matchedAlphabets.length
        ) {
            this.state.categoryWords.splice(this.randomNumber, 1);
            this.setState((prevState) => {
                return {
                    score: prevState.score + 1,
                    totalLives: Constants.TotalLives,
                    totalWordCount: prevState.totalWordCount - 1,
                    clickedAlphabets: [],
                    matchedAlphabets: [],
                    wordAlphabets: [],
                    categoryWordCount: prevState.categoryWordCount - 1,
                    categoryWords: prevState.categoryWords,
                };
            });
            if (this.state.categoryWords.length === 0) {
                this.completedCategory.push(this.state.selectedCategory);
                this.setState({
                    categoryComplete: this.completedCategory,
                    clickedAlphabets: [],
                    matchedAlphabets: [],
                    wordAlphabets: [],
                    selectedCategory: "",
                    categoryWords: "",
                    randomWord: "",
                    categoryWordCount: 0,
                });
            } else {
                this.setState({
                    clickedAlphabets: [],
                    matchedAlphabets: [],
                    wordAlphabets: [],
                    randomWord: "",
                });
                setTimeout(() => {
                    this.randomWord();
                }, 1000);
            }
        }

        if (this.state.totalWordCount === 0) {
            this.finalScore = this.state.score;
            this.setState({
                gameState: "end",
                selectedCategory: "",
                categoryWords: "",
                randomWord: "",
                clickedAlphabets: [],
                matchedAlphabets: [],
                wordAlphabets: [],
                score: 0,
                totalWordCount: Constants.TotalWords,
                categoryWordCount: 0,
                totalLives: Constants.TotalLives,
                isCategoryClicked: "",
                categoryComplete: [],
            });
        }
    }

    createLifeEmojis = () => {
        let lives = [];
        for (let i = 1; i <= this.state.totalLives; i++) {
            lives.push(
                <span
                    key={"life_" + i}
                    id={"life_" + i}
                    role="img"
                    aria-label="heart-emoji"
                >
                    ❤️
                </span>
            );
        }
        return lives;
    };

    render() {
        return (
            <>
                <div
                    style={
                        (this.state.gameState === "running" ||
                            this.state.gameState !== "") &&
                        this.state.gameState !== "end"
                            ? { display: "block" }
                            : this.state.gameState === "end"
                            ? { display: "block", opacity: 0.3 }
                            : { display: "none" }
                    }
                >
                    <div id="category-header-container"></div>
                    <div id="category-container">
                        <Category
                            categoryList={this.state.categoryList}
                            categoryComplete={this.completedCategory}
                            isCategoryClicked={this.state.isCategoryClicked}
                            gameState={this.state.gameState}
                            clickHandle={this.selectCategory}
                        />
                    </div>
                    <p style={{ textAlign: "center", margin: "2px" }}>
                        {this.state.gameState !== "" &&
                        this.state.selectedCategory !== ""
                            ? `Selected category is ${
                                  this.state.selectedCategory +
                                  Constants.getEmoji(
                                      this.state.selectedCategory
                                  )
                              }`
                            : `select a category ⬆️`}
                    </p>
                    {/* CLUES HERE */}
                    {/* <p
                        style={{
                            textAlign: "center",
                            margin: "5px",
                            fontWeight: "bold",
                        }}
                    >
                        {this.state.gameState !== "" &&
                        this.state.selectedCategory !== ""
                            ? `Clue : ${this.clue}`
                            : ``}
                    </p> */}
                    <div className="separator">
                        <hr style={{ color: "transparent" }} />
                    </div>
                    <div id="dynamic-elements-container">
                        <div id="word-container">
                            <Word
                                wordAlphabetsList={this.state.wordAlphabets}
                                matchedAlphabets={this.state.matchedAlphabets}
                            />
                        </div>
                        <div id="game-board-container">
                            <Alphabets
                                alphabetList={this.state.alphabetList}
                                selectedCategory={this.state.selectedCategory}
                                clickedAlphabets={this.state.clickedAlphabets}
                                matchedAlphabets={this.state.matchedAlphabets}
                                gameStatus={this.state.gameState}
                                clickHandle={this.checkAlphabet}
                            />
                        </div>
                    </div>
                    <div id="stats-container">
                        <p>Score : {this.state.score}</p>
                        <p>Lives : {this.createLifeEmojis()}</p>
                    </div>
                    <div id="word-count-container">
                        <p
                            style={
                                this.state.selectedCategory !== ""
                                    ? { opacity: 1 }
                                    : { opacity: 0 }
                            }
                        >
                            Words remaining in this category :{" "}
                            {this.state.categoryWordCount}
                        </p>
                    </div>
                </div>
                <div
                    style={
                        this.state.gameState === "end" &&
                        this.state.gameState !== "" &&
                        this.state.gameState !== "running"
                            ? { display: "block" }
                            : { display: "none" }
                    }
                >
                    <GameEnd
                        score={this.finalScore}
                        startGame={this.startGame}
                    />
                </div>
            </>
        );
    }
}

export default GameBoard;
