import React from "react";
import "./Header.css";

function Header() {
    return (
        <>
            <div id="navbar">CUIZ NIGHT</div>
        </>
    );
}

export default Header;
